/** Inter Font */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Inter/Inter-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Inter/Inter-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/Inter/Inter-SemiBold.woff') format('woff');
}

/** Color pallete */
$gray: #6c757d;

/** loader animation */
.loader-root {
  text-align: center;
}

.loader {
  align-items: center;
  display: flex;
  font-size: 28px;
  height: 100vh;
  padding-left: 20px;
  position: relative;
}

.loader:before {
  animation: loading 2s linear infinite;
  content: attr(data-text);
  left: 20px;
  max-width: 180px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}

.loader-light {
  color: rgba(0, 0, 0, .3);
}

.loader-light:before {
  color: rgba(0, 0, 0, .5);
}

.loader-dark {
  background-color: rgba(0, 0, 0, .8);
  color: rgba(255, 255, 255, .3);
}

.loader-dark:before {
  color: rgba(255, 255, 255, .5);
}



/** Component styles */
body {
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  /* prevent overscroll bounce*/
  // background-color: lightgreen;
  // overflow-y: scroll;
  -ms-user-select: none; /* Internet Explorer/Edge */
  /* iOS velocity scrolling */
  // width: 50%;
  // margin-left: 25%;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  font-family: Inter;
  height: 100%;
  margin: 0;
  padding: 0;
  // max-height: 100vh;
  // max-width: 100vw;
  position: fixed;
  // min-height: 100vh;
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  width: 100vw;
}

::-webkit-scrollbar {
  width: 0;
}

/** React toast */
.Toastify__toast-body {
  font-size: 18px;
}

.Toastify__toast {
  border-radius: 5px !important;
  margin: 0;
  margin-bottom: 10px !important;
  min-height: 50px !important;
  padding: 15px !important;
}
